import React, { useState } from 'react';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin,
} from 'react-google-login';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { loggedIn } from '../store/auth/actions';
import { CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage:
      'url(https://familoapp.com/wp-content/uploads/2020/10/img-team-large-new-scaled.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  loginButtonContainer: {
    width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(3, 0, 3),
    display: 'flex',
    'justify-content': 'center',
  },
}));

export const Login: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = React.useState('');

  const { signIn } = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID as string,
    cookiePolicy: 'single_host_origin',
    uxMode: 'popup',
    onRequest: () => {
      setAlert('');
      setLoading(true);
    },
    onSuccess: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      setLoading(false);
      if (response.code) {
        // TODO: Error handling!
        console.error(
          'Google Login failed with GoogleLoginResponseOffline',
          response
        );
      } else {
        console.info('Google Login successful', response);
        dispatch(loggedIn(response as GoogleLoginResponse));
        history.push('/');
      }
    },
    onFailure: (err: Error) => {
      setLoading(false);
      // TODO: Error handling!
      console.error('Google Login failed', err);
      setAlert('Google Login failed');
    },
  });

  const classes = useStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" align="center">
            The Familonet Admin Dashboard
          </Typography>
          <div className={classes.loginButtonContainer}>
            <Button variant="contained" color="primary" onClick={signIn}>
              Login with Google Account
            </Button>
          </div>
          {loading && (
            <div>
              <CircularProgress />
            </div>
          )}
          {alert && (
            <div>
              <Alert severity="error" style={{ marginBottom: '8px' }}>
                {alert}
              </Alert>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
