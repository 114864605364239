import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#fafafa' },
    primary: {
      light: 'rgba(255, 104, 20, 1)',
      main: 'rgba(255, 91, 0, 1)',
      dark: 'rgba(240, 86, 0, 1)',
      contrastText: '#fff',
    },
    secondary: {
      light: 'rgba(255, 255, 255, 1)',
      main: 'rgba(0, 0, 0, 0.54)',
      dark: 'rgba(247, 247, 247, 1)',
      contrastText: 'rgba(0, 0, 0, 1)',
    },
    error: {
      light: 'rgba(255, 75, 57, 1)',
      main: 'rgba(255, 17, 0, 1)',
      dark: 'rgba(242, 52, 33, 1)',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  typography: {
    fontSize: 14,
    htmlFontSize: 10,
  },
});
