import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { receivedUserUpdateResponse } from '../../store/customers/actions';
import { familonetApi } from '../../familonetApi';
import { Customer } from '../../store/customers/types';

export const AssignEmail: React.FC<{ email?: string; id: string }> = ({
  email,
  id,
}) => {
  const [open, setOpen] = React.useState(false);
  const [newEmail, setNewEmail] = React.useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState('');
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setNewEmail(email || '');
    setOpen(true);
    setError('');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    setError('');
    setLoading(true);
    familonetApi<{ model: Customer }>({
      method: 'PUT',
      path: `/api/v4/useradmin/${id}/`,
      body: { email: newEmail },
      setResult: (body) => {
        dispatch(receivedUserUpdateResponse(body.model));
        handleClose();
      },
      setError,
      setLoading,
    });
  };

  return (
    <div style={{ margin: '8px 8px 8px 0' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        style={{ marginBottom: '8px' }}
      >
        Assign new Email
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Assign Email</DialogTitle>

        <DialogContent>
          <DialogContentText>
            You can assign a new email address to the user account:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            value={newEmail}
            onChange={(event) => {
              setNewEmail(event.target.value);
            }}
          />

          {error && (
            <Alert severity="error" style={{ marginBottom: '8px' }}>
              {error}
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleUpdate}
            color="primary"
            variant="contained"
          >
            Update Account
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
