import { SearchResult } from '../store/customers/types';
import { useFamilonetApi } from './useFamilonetApi';
import { receivedSearchResult } from '../store/customers/actions';
import { store } from '../store';
import { useEffect } from 'react';

interface SearchResultResponse {
  models: SearchResult[];
  status: string;
  count: number;
  total_count: number;
  page_count: number;
  page: number;
}

export const useUserSearch = (
  searchTerm?: string
): { result?: SearchResult[]; error: string; loading: boolean } => {
  const path = searchTerm
    ? `/api/v4/useradmin/?search=${encodeURIComponent(searchTerm)}`
    : undefined;

  const { result, error, loading } = useFamilonetApi<SearchResultResponse>({
    method: 'GET',
    path,
    delay: 500,
  });

  // Why useEffect is required: https://stackoverflow.com/questions/62336340/cannot-update-a-component-while-rendering-a-different-component-warning#answer-63424831
  useEffect(() => {
    if (result) {
      store.dispatch(receivedSearchResult(result.models));
    }
  }, [result]);

  return { result: result?.models, error, loading };
};
