import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';

export const Confirmation: React.FC<{
  disabled: boolean;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onAbort: () => void;
  error?: string;
}> = ({ disabled, open, onClose, onConfirm, onAbort, error }) => {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Sending</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to send the mailing now?
          </DialogContentText>
          {error && (
            <Alert severity="error" style={{ marginBottom: '8px' }}>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onAbort}
            color="secondary"
            disabled={disabled}
            autoFocus
          >
            Abort
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            disabled={disabled}
            variant="contained"
          >
            Send now
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
