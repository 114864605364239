import React, { useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import { CircularProgress } from '@material-ui/core';
import { useUserSearch } from '../../hooks/useUserSearch';
import Alert from '@material-ui/lab/Alert';

export const Search: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { error, loading } = useUserSearch(searchTerm);

  return (
    <div>
      <TextField
        fullWidth
        variant="outlined"
        label="Search for Customer"
        placeholder="E-Mail or Phone Number"
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading ? <CircularProgress /> : <SearchIcon />}
            </InputAdornment>
          ),
        }}
        value={searchTerm}
        onChange={(event) => {
          setSearchTerm(event.target.value);
        }}
      />
      {error && (
        <Alert severity="error" style={{ margin: '8px' }}>
          {error}
        </Alert>
      )}
    </div>
  );
};
