import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PeopleIcon from '@material-ui/icons/People';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import MailIcon from '@material-ui/icons/Mail';
import { useGoogleLogout } from 'react-google-login';
import { loggedOut } from '../../store/auth/actions';

export const Menu: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { signOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID as string,
    onLogoutSuccess: () => {
      dispatch(loggedOut());
    },
  });

  return (
    <React.Fragment>
      <div>
        <ListItem
          button
          component={Link}
          to="/customers"
          selected={location.pathname === '/customers'}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/mailing"
          selected={location.pathname === '/mailing'}
        >
          <ListItemIcon>
            <MailIcon />
          </ListItemIcon>
          <ListItemText primary="Mailings" />
        </ListItem>
      </div>
      <Divider />
      <div>
        <ListItem
          button
          component={Link}
          to="/settings"
          selected={location.pathname === '/settings'}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button onClick={signOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sign out" />
        </ListItem>
      </div>
    </React.Fragment>
  );
};
