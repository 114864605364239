import React, { SyntheticEvent, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Layout } from '../Layout';
import { Confirmation } from './Confirmation';
import Alert from '@material-ui/lab/Alert';
import Fab from '@material-ui/core/Fab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { familonetApi } from '../../familonetApi';

export const MailEditor: React.FC = () => {
  const classes = useStyles();
  const [mailContent, setMailContent] = useState('');
  const [mailGreeting, setMailGreeting] = useState('');
  const [environment, setEnvironment] = useState('dev');
  const [language, setLanguage] = useState('de');
  const [subject, setSubject] = useState('');
  const [from, setFrom] = useState('Hauke <support@familo.net>');
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmationDialogDisabled, setConfirmationDialogDisabled] = useState(
    false
  );
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');

  function restart() {
    setMailContent('');
    setMailGreeting('');
    setConfirmationDialogDisabled(false);
    setError('');
    setSuccess('');
  }

  function processTinyMCESubmit(e: SyntheticEvent<HTMLIFrameElement>) {
    const search = e.currentTarget.contentWindow?.location.search;
    if (search) {
      const urlParams = new URLSearchParams(search);
      const newMailContent = urlParams.get('mail-content');
      const newMailGreeting = urlParams.get('mail-greeting');

      if (newMailContent) {
        // Hack to update TinyMCE autosave plugin to latest state
        localStorage.setItem(
          'tinymce-autosave-/mailings/mail-editor.html-mail-content-draft',
          newMailContent
        );
        setMailContent(newMailContent);
      }

      if (newMailGreeting) {
        // Hack to update TinyMCE autosave plugin to latest state
        localStorage.setItem(
          'tinymce-autosave-/mailings/mail-editor.html-mail-greeting-draft',
          newMailGreeting
        );
        setMailGreeting(newMailGreeting);
      }

      // Hack for the TinyMCE autosave plugin to work properly
      e.currentTarget.contentWindow!.location.search = '';
    }
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setError('');
    setSuccess('');
    setConfirmationOpen(true);
  }

  async function confirmationOnConfirm() {
    setConfirmationDialogDisabled(true);
    const url = `${process.env.PUBLIC_URL}/mailings/mail-template.html`;

    fetch(url)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(
            'Fetching mail template failed with status code ' + response.status
          );
        }

        return response.text().then((body) => {
          body = body.replace('%MAIL-CONTENT%', mailContent);
          body = body.replace('%MAIL-GREETING%', mailGreeting);
          console.log('Fetched mail template');
          return sendMailing(body);
        });
      })
      .catch((err) => {
        console.error('Sending mailing failed', err);
        setError(err.message);
        setConfirmationOpen(false);
        setConfirmationDialogDisabled(false);
      });
  }

  async function sendMailing(body: string): Promise<void> {
    familonetApi({
      method: 'POST',
      path: `/api/v4/mailingadmin/`,
      body: { environment, language, subject, from, body },
      setResult: (body) => {
        setConfirmationOpen(false);
        setConfirmationDialogDisabled(false);
        setSuccess('Mailing sent successfully :-)');
      },
      setError: (message) => {
        setError(message);
        setConfirmationDialogDisabled(false);
      },
      // setLoading,
    });
  }

  const ref = useRef(null);

  return (
    <Layout>
      {success && (
        <Alert severity="success" style={{ marginBottom: '8px' }}>
          {success}
        </Alert>
      )}

      {mailContent && mailGreeting ? (
        <form onSubmit={onSubmit}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel required={true} component="legend">
              Test Run?
            </FormLabel>
            <RadioGroup
              aria-label="environment"
              name="environment"
              value={environment}
              onChange={(e) => {
                setEnvironment(e.target.value);
              }}
            >
              <FormControlLabel
                value="dev"
                control={<Radio />}
                label="Test Run"
              />
              <FormControlLabel
                value="release"
                control={<Radio />}
                label="Send to real Users"
              />
            </RadioGroup>
          </FormControl>

          <FormControl className={classes.formControl}>
            <InputLabel required={true} id="language-label">
              Language
            </InputLabel>
            <Select
              required={true}
              labelId="language-label"
              id="language"
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value as string);
              }}
            >
              <MenuItem value="de">German</MenuItem>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Spanish</MenuItem>
              <MenuItem value="pt">Portuguese</MenuItem>
              <MenuItem value="tr">Turkish</MenuItem>
              <MenuItem value="others">Others</MenuItem>
            </Select>
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              required={true}
              style={{ width: '35rem' }}
              id="subject"
              label="Mail Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              required={true}
              style={{ width: '25rem' }}
              id="from"
              label="Mail From"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
            />
          </FormControl>

          <Confirmation
            disabled={confirmationDialogDisabled}
            open={confirmationOpen}
            onClose={() => setConfirmationOpen(false)}
            onConfirm={confirmationOnConfirm}
            onAbort={() => setConfirmationOpen(false)}
            error={error}
          />

          <Button
            variant="outlined"
            color="default"
            style={{ marginRight: '16px' }}
            onClick={restart}
          >
            Back
          </Button>

          <Button variant="contained" color="primary" type="submit">
            Send Mailing
          </Button>
        </form>
      ) : (
        <div style={{ position: 'relative', height: '100%', padding: '16px' }}>
          <Fab
            color="primary"
            variant="extended"
            aria-label="continue"
            style={{ position: 'absolute', bottom: '0', right: '0' }}
            onClick={() => {
              let iFrameDoc =
                (ref.current as any)!.contentWindow ||
                (ref.current as any)!.contentDocument;
              if (iFrameDoc.document) {
                iFrameDoc = iFrameDoc.document;
              }
              iFrameDoc.getElementById('form').submit();
            }}
          >
            Continue
            <NavigateNextIcon />
          </Fab>
          <iframe
            ref={ref}
            title="Mail Editor"
            onLoad={processTinyMCESubmit}
            width="100%"
            height="100%"
            src={process.env.PUBLIC_URL + '/mailings/mail-editor.html'}
          />
        </div>
      )}
    </Layout>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(2),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(3),
    },
  })
);
