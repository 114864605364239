import { loggedOut } from './store/auth/actions';
import { store } from './store';

const familonetApiGateway = async <ResponseType>({
  method,
  url,
  body,
  authToken,
  controller,
}: {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  body?: object;
  authToken?: string;
  controller: AbortController;
}): Promise<ResponseType> => {
  const headers = {
    Authorization: `Bearer ${authToken}`,
    'Content-Type': 'application/json',
  };
  const signal = controller.signal;

  try {
    const response = await fetch(url, {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
      signal,
      mode: 'cors',
    });
    if (response.status === 401 || response.status === 403) {
      store.dispatch(loggedOut());
      throw new Error('Authentication error: You will be logged out!');
    } else {
      const body = await response.json();
      console.log(`${method} ${url} response body received`, body);
      if (body.status !== 'ok') {
        throw new Error(
          `${body.message || 'Unknown error'} (${response.status}, ${
            body.type || 'Unknown type'
          })`
        );
      }
      return body as ResponseType;
    }
  } catch (e) {
    console.error(`${method} ${url} request failed`, e);
    throw e;
  }
};

export const familonetApi = <ResponseType>({
  method,
  path,
  body,
  setResult,
  setError,
  setLoading,
  controller,
}: {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path: string;
  body?: object;
  setResult: (result: ResponseType) => void;
  setError?: (error: string) => void;
  setLoading?: (loading: boolean) => void;
  controller?: AbortController;
}) => {
  const authToken = store.getState().auth.user?.tokenId;
  const host = store.getState().system.env.host;
  const url = `https://${host}${path}`;

  setLoading && setLoading(true);
  familonetApiGateway<ResponseType>({
    method,
    url,
    body,
    authToken,
    controller: controller || new AbortController(),
  })
    .then((result: ResponseType) => {
      if (!controller?.signal.aborted) {
        setLoading && setLoading(false);
        setResult(result);
      }
    })
    .catch((e) => {
      if (!controller?.signal.aborted) {
        setLoading && setLoading(false);
        setError && setError(e.message);
      }
    });
};
