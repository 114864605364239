import {
  GoogleUser,
  LOGGED_IN,
  LOGGED_OUT,
  LoggedInAction,
  LoggedOutAction,
} from './types';

export function loggedIn(user: GoogleUser): LoggedInAction {
  localStorage.setItem('user', JSON.stringify(user));

  return {
    type: LOGGED_IN,
    user: user,
  };
}

export function loggedOut(): LoggedOutAction {
  localStorage.removeItem('user');
  return {
    type: LOGGED_OUT,
  };
}
