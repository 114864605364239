import {
  SystemState,
  CHANGE_ENVIRONMENT,
  SystemActionTypes,
  devEnv,
  prodEnv,
} from './types';

function getPersistedState(): SystemState {
  return { env: localStorage.getItem('env') === 'dev' ? devEnv : prodEnv };
}

const initialState = getPersistedState();

export function systemReducer(
  state = initialState,
  action: SystemActionTypes
): SystemState {
  switch (action.type) {
    case CHANGE_ENVIRONMENT:
      return action.env === 'dev' ? { env: devEnv } : { env: prodEnv };
    default:
      return state;
  }
}
