import {
  CustomerState,
  RECEIVED_SEARCH_RESULT,
  SELECT_SEARCH_RESULT,
  RECEIVED_USER_UPDATE_RESPONSE,
  CustomerActionTypes,
  RECEIVED_USER_DELETE_RESPONSE,
} from './types';

const initialState: CustomerState = {
  searchResults: [],
  selectedSearchResult: null,
};

export function customerReducer(
  state = initialState,
  action: CustomerActionTypes
): CustomerState {
  switch (action.type) {
    case RECEIVED_SEARCH_RESULT:
      return {
        searchResults: action.searchResults,
        selectedSearchResult: null,
      };
    case SELECT_SEARCH_RESULT:
      return {
        searchResults: state.searchResults,
        selectedSearchResult: action.searchResult,
      };
    case RECEIVED_USER_UPDATE_RESPONSE:
      return {
        searchResults: state.searchResults.map((result) => {
          return result.user._id === action.user._id
            ? { ...result, user: action.user }
            : result;
        }),
        selectedSearchResult:
          state.selectedSearchResult?._id === action.user._id
            ? { ...state.selectedSearchResult, user: action.user }
            : null,
      };
    case RECEIVED_USER_DELETE_RESPONSE:
      return {
        searchResults: state.searchResults.filter(
          (result) => result.user._id !== action.user_id
        ),
        selectedSearchResult: null,
      };
    default:
      return state;
  }
}
