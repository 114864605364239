import { CHANGE_ENVIRONMENT, ChangeEnvironmentAction } from './types';

export function changeEnvironment(
  env: 'dev' | 'prod'
): ChangeEnvironmentAction {
  localStorage.setItem('env', env);
  return {
    type: CHANGE_ENVIRONMENT,
    env,
  };
}
