import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { changeEnvironment } from '../../store/system/actions';

export const Environment: React.FC = () => {
  const env = useSelector((state: AppState) => state.system.env.name);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const env = (event.target as HTMLInputElement).value as 'dev' | 'prod';
    dispatch(changeEnvironment(env));
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Environment</FormLabel>
      <RadioGroup
        aria-label="environment"
        name="environment"
        value={env}
        onChange={handleChange}
      >
        <FormControlLabel value="dev" control={<Radio />} label="Dev" />
        <FormControlLabel value="prod" control={<Radio />} label="Prod" />
      </RadioGroup>
    </FormControl>
  );
};
