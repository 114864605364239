import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import Chip from '@material-ui/core/Chip';

export const ConsistencyAlerts: React.FC<{ alerts: string[] }> = (props) => {
  return (
    <div style={{ margin: '8px 0' }}>
      {props.alerts.length === 0 ? (
        <Alert severity="success">
          This user has no active consistency alerts
        </Alert>
      ) : (
        <Alert severity="warning">
          <AlertTitle>
            <strong>Consistency Alert</strong>
          </AlertTitle>
          {props.alerts.map((alert) => (
            <Chip
              key={alert}
              label={alert}
              color="primary"
              variant="outlined"
              style={{ marginRight: '8px' }}
            />
          ))}
        </Alert>
      )}
    </div>
  );
};
