import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Layout } from '../Layout';
import { Search } from './Search';
import { Results } from './Results';
import { Details } from './Details';
import { useStyles } from '../Layout/style';

export const Customers: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Search />
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <Results />
          </Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <Details />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
};
