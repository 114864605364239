// Entity Interfaces
export interface GoogleUser {
  googleId: string;
  tokenId: string;
  accessToken: string;
  tokenObj: object;
  profileObj: object;
}

// State Interfaces
export interface AuthState {
  user: GoogleUser | null;
}

// Describing the different ACTION NAMES available
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

// Action Interfaces
export interface LoggedInAction {
  type: typeof LOGGED_IN;
  user: GoogleUser;
}

export interface LoggedOutAction {
  type: typeof LOGGED_OUT;
}

// Action Union
export type AuthActionTypes = LoggedInAction | LoggedOutAction;
