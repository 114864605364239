import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FaceIcon from '@material-ui/icons/Face';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { selectSearchResult } from '../../store/customers/actions';

export const Results: React.FC = () => {
  const dispatch = useDispatch();
  const searchResults = useSelector(
    (state: AppState) => state.customer.searchResults
  );

  return (
    <List
      component="nav"
      aria-labelledby="customer-search-results"
      dense
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Customers
        </ListSubheader>
      }
    >
      {searchResults.map((result) => {
        const secondaries = [result.user.email, result.user.phone]
          .filter((value) => !!value)
          .join(', ');

        return (
          <ListItem
            button
            key={result._id}
            onClick={() => {
              dispatch(selectSearchResult(result));
            }}
          >
            <ListItemIcon>
              <FaceIcon />
            </ListItemIcon>
            <ListItemText
              primary={result.user.name}
              secondary={secondaries}
              primaryTypographyProps={{ noWrap: true }}
              secondaryTypographyProps={{ noWrap: true }}
            />
          </ListItem>
        );
      })}
    </List>
  );
};
