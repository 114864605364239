import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { AssignEmail } from './AssignEmail';
import { DeleteUser } from './DeleteUser';
import { CircularProgress, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';
import { ConsistencyAlerts } from './ConsistencyAlerts';
import { useUserDetails } from '../../hooks/useUserDetails';

export const Details: React.FC = () => {
  const searchResult = useSelector(
    (state: AppState) => state.customer.selectedSearchResult
  );

  const { result, error, loading } = useUserDetails(searchResult?._id);

  if (searchResult) {
    const secondaries = [searchResult.user.email, searchResult.user.phone]
      .filter((value) => !!value)
      .join(', ');

    return (
      <div>
        <Typography gutterBottom variant="h5">
          {searchResult.user.name}
          {searchResult.user.premium && (
            <Tooltip title="Premium User">
              <StarIcon color="primary" />
            </Tooltip>
          )}
        </Typography>
        <Typography gutterBottom variant="h6">
          {secondaries}
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error" style={{ margin: '8px' }}>
            {error}
          </Alert>
        ) : result !== undefined ? (
          <div>
            <ConsistencyAlerts alerts={result.consistency_alerts} />
            <div style={{ display: 'flex', flexFlow: 'row' }}>
              <AssignEmail
                email={searchResult.user.email}
                id={searchResult.user._id}
              />
              <DeleteUser id={searchResult.user._id} />
            </div>
            <pre style={{ maxWidth: '50rem', overflowX: 'scroll' }}>
              {JSON.stringify(result, null, 2)}
            </pre>
          </div>
        ) : null}
      </div>
    );
  } else return null;
};
