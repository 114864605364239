// Entity Interfaces
export interface Customer {
  _id: string;
  name: string;
  email?: string;
  phone?: string;
  premium?: boolean;
}

export interface SearchResult {
  _id: string;
  user: Customer;
  usersettings: {};
  circles: {};
}

export interface UserDetailsResult {
  status: string;
  _id: string;
  user: Customer;
  usersettings: {};
  trackings: {};
  consistencies: {};
  consistency_alerts: string[];
  last_measured: number;
}

// State Interfaces
export interface CustomerState {
  searchResults: SearchResult[];
  selectedSearchResult: SearchResult | null;
}

// Describing the different ACTION NAMES available
export const RECEIVED_SEARCH_RESULT = 'RECEIVED_SEARCH_RESULT';
export const SELECT_SEARCH_RESULT = 'SELECT_SEARCH_RESULT';
export const RECEIVED_USER_UPDATE_RESPONSE = 'RECEIVED_USER_UPDATE_RESPONSE';
export const RECEIVED_USER_DELETE_RESPONSE = 'RECEIVED_USER_DELETE_RESPONSE';

// Action Interfaces
export interface ReceivedSearchResultAction {
  type: typeof RECEIVED_SEARCH_RESULT;
  searchResults: SearchResult[];
}

export interface SelectSearchResultAction {
  type: typeof SELECT_SEARCH_RESULT;
  searchResult: SearchResult;
}

export interface ReceivedUserUpdateResponseAction {
  type: typeof RECEIVED_USER_UPDATE_RESPONSE;
  user: Customer;
}

export interface ReceivedUserDeleteResponseAction {
  type: typeof RECEIVED_USER_DELETE_RESPONSE;
  user_id: string;
}

// Action Union
export type CustomerActionTypes =
  | ReceivedSearchResultAction
  | SelectSearchResultAction
  | ReceivedUserUpdateResponseAction
  | ReceivedUserDeleteResponseAction;
