import {
  RECEIVED_SEARCH_RESULT,
  SELECT_SEARCH_RESULT,
  RECEIVED_USER_UPDATE_RESPONSE,
  RECEIVED_USER_DELETE_RESPONSE,
  ReceivedSearchResultAction,
  SelectSearchResultAction,
  ReceivedUserUpdateResponseAction,
  ReceivedUserDeleteResponseAction,
  SearchResult,
  Customer,
} from './types';

export function receivedSearchResult(
  searchResults: SearchResult[]
): ReceivedSearchResultAction {
  return {
    type: RECEIVED_SEARCH_RESULT,
    searchResults,
  };
}

export function selectSearchResult(
  searchResult: SearchResult
): SelectSearchResultAction {
  return {
    type: SELECT_SEARCH_RESULT,
    searchResult,
  };
}

export function receivedUserUpdateResponse(
  user: Customer
): ReceivedUserUpdateResponseAction {
  return {
    type: RECEIVED_USER_UPDATE_RESPONSE,
    user,
  };
}

export function receivedUserDeletedResponse(
  user_id: string
): ReceivedUserDeleteResponseAction {
  return {
    type: RECEIVED_USER_DELETE_RESPONSE,
    user_id,
  };
}
