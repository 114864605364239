import {
  AuthState,
  LOGGED_IN,
  LOGGED_OUT,
  AuthActionTypes,
  GoogleUser,
} from './types';

function getPersistedUser(): GoogleUser | null {
  const encodedUser = localStorage.getItem('user');
  if (encodedUser) {
    const decodedUser = JSON.parse(encodedUser) as GoogleUser;
    return decodedUser;
  }

  return null;
}

const initialState: AuthState = {
  user: getPersistedUser(),
};

export function authReducer(
  state = initialState,
  action: AuthActionTypes
): AuthState {
  switch (action.type) {
    case LOGGED_IN:
      return { user: action.user };
    case LOGGED_OUT:
      return { user: null };
    default:
      return state;
  }
}
