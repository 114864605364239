import React, { useEffect, useState } from 'react';
import { familonetApi } from '../familonetApi';

export const useFamilonetApi = <ResponseType>({
  method,
  path,
  body,
  delay,
}: {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path?: string;
  body?: object;
  delay?: number;
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState('');
  const [result, setResult] = React.useState<ResponseType>();

  useEffect(() => {
    setError('');
    const controller = new AbortController();

    if (path === undefined) {
      return;
    }

    const executeFamilonetApiFetch = () =>
      familonetApi({
        method,
        path,
        body,
        setResult,
        setError,
        setLoading,
        controller,
      });

    if (delay) {
      const timer = setTimeout(executeFamilonetApiFetch, delay);
      return () => {
        clearTimeout(timer);
        controller.abort();
      };
    } else {
      executeFamilonetApiFetch();
      return () => {
        controller.abort();
      };
    }
  }, [method, path, body, setError, setResult, setLoading, delay]);

  return { result, error, loading };
};
