import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Login } from './Login';
import { Customers } from './Customers';
import { Settings } from './Settings';
import { MailEditor } from './Mailing';
import { useAuthenticated } from '../hooks/useAuthenticated';

export const AppRouter: React.FC = () => {
  const authenticated = useAuthenticated();

  if (authenticated) {
    return (
      <div>
        <Switch>
          <Route path="/" exact={true}>
            <Redirect to="/customers"></Redirect>
          </Route>
          <Route path="/customers" exact={true}>
            <Customers />
          </Route>
          <Route path="/mailing" exact={true}>
            <MailEditor />
          </Route>
          <Route path="/settings" exact={true}>
            <Settings />
          </Route>
          <Route path="/login" exact={true}>
            <Login />
          </Route>
          <Route>404 - Not Found</Route>
        </Switch>
      </div>
    );
  } else {
    return (
      <div>
        <Switch>
          <Route path="/login" exact={true}>
            <Login />
          </Route>
          <Redirect to="/login"></Redirect>
        </Switch>
      </div>
    );
  }
};
