import { createStore, combineReducers, applyMiddleware, Action } from 'redux';
import thunkMiddleware, { ThunkAction, ThunkMiddleware } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authReducer } from './auth/reducers';
import { customerReducer } from './customers/reducers';
import { systemReducer } from './system/reducers';

const rootReducer = combineReducers({
  system: systemReducer,
  auth: authReducer,
  customer: customerReducer,
});

// For redux typing https://github.com/reduxjs/redux-thunk/blob/master/test/typescript.ts#L60
export type AppState = ReturnType<typeof rootReducer>;
export type ThunkResult<R> = ThunkAction<
  R,
  AppState,
  undefined,
  Action<string>
>;

export function configureStore() {
  const middleWareEnhancer = applyMiddleware(
    thunkMiddleware as ThunkMiddleware<AppState, Action<string>>
  );

  return createStore(rootReducer, composeWithDevTools(middleWareEnhancer));
}

export const store = configureStore();

export type AppDispatch = typeof store.dispatch;
