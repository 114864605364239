// Entity Interfaces
export const devEnv = {
  name: 'dev',
  host: 'dev-api.familonet-api.net',
};

export const prodEnv = {
  name: 'prod',
  host: 'api.familonet-api.net',
};

type Environment = typeof devEnv | typeof prodEnv;

// State Interfaces
export interface SystemState {
  env: Environment;
}

// Describing the different ACTION NAMES available
export const CHANGE_ENVIRONMENT = 'CHANGE_ENVIRONMENT';

// Action Interfaces
export interface ChangeEnvironmentAction {
  type: typeof CHANGE_ENVIRONMENT;
  env: 'dev' | 'prod';
}

// Action Union
export type SystemActionTypes = ChangeEnvironmentAction;
