import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { receivedUserDeletedResponse } from '../../store/customers/actions';
import { familonetApi } from '../../familonetApi';

export const DeleteUser: React.FC<{ id: string }> = ({ id }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState('');
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
    setError('');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    setError('');
    setLoading(true);
    familonetApi({
      method: 'DELETE',
      path: `/api/v4/useradmin/${id}/`,
      setResult: (body) => {
        handleClose();
        dispatch(receivedUserDeletedResponse(id));
      },
      setError,
      setLoading,
    });
  };

  return (
    <div style={{ margin: '8px' }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        style={{ marginBottom: '8px' }}
      >
        Delete User
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete User</DialogTitle>

        <DialogContent>
          <DialogContentText>
            ARE YOU SURE YOU WANT TO DELETE THIS USER? YOU CANNOT UNDO THIS!
          </DialogContentText>

          {error && (
            <Alert severity="error" style={{ marginBottom: '8px' }}>
              {error}
            </Alert>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            disabled={loading}
            onClick={handleClose}
            color="primary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            disabled={loading}
            onClick={handleDelete}
            color="primary"
            variant="contained"
          >
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
