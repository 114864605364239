import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Layout } from '../Layout';
import { Environment } from './Environment';
import { useStyles } from '../Layout/style';

export const Settings: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Paper className={classes.paper}>
        <Environment />
      </Paper>
    </Layout>
  );
};
